<template>
  <v-container fluid class="px-1 py-0">
    <v-card class="border-radius-xl mt-3 pb-2"> 123 </v-card>
  </v-container>
</template>

<script>
import Bus from "@/components/Bus/bus.js";

export default {
  name: "Manage",
  components: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    Bus.$on("adminTabChange", (e) => {
      this.currentTab = e;
    });
  },
  methods: {},
};
</script>
